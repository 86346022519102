import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCopy, faBars } from '@fortawesome/free-solid-svg-icons';
import onClickOutside from 'react-onclickoutside';
import { withTranslation } from 'react-i18next';

class TableMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  _closeMenu() {
    this.props.closeMenu();
    clearTimeout(this.timeoutID);
  }

  handleClickOutside() {
    this._closeMenu();
  }

  closeMenuWithDelay = () => {
    this.timeoutID = setTimeout(() => this.props.closeMenu(),2000)
  }

  cancelCloseMenuWithDelay = () => {
    clearTimeout(this.timeoutID);
  }

  render() {
    const {t} = this.props;
    return (
      <div className="tableMenu" onMouseLeave={this.closeMenuWithDelay} onMouseEnter={this.cancelCloseMenuWithDelay}>
        <div className="tableMenuOption" onClick={this.props.openInfoModal}>
          <FontAwesomeIcon className="chartIcon" icon={faInfoCircle} />
          {t('about_table')}
        </div>
      </div>
    )
  }
}

export default withTranslation()(onClickOutside(TableMenu));