import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './info-modal.css';

const InfoModal = (props) => {
  const {t} = props;
  return(
    <div className={`infoModalContainer ${props.classSuffix}`} onClick={props.closeInfoModal}>
      <div className={`infoModal ${props.classSuffix}`} onClick={(e) => {e.stopPropagation()}}>
        {props.classSuffix === 'visible' ?
          <div>
            <div className="infoModalClose">
              <FontAwesomeIcon onClick={props.closeInfoModal} id="modalClose" color="#000" icon={faTimes} size="2x" />
            </div>
            <div className="infoModalMessage">
              <div>{t(`graph_desc.${props.message}`).split('\n').map(s => <div key={s} className="infoModalText">{s}</div>)}</div>
              {props.grouping && props.grouping != t(props.grouping) && <div className="infoModalTable"><table><thead></thead><tbody>{t(`${props.grouping}`).split('\n').map(s => <tr key={s}>{s.split(':').map(e => <td key={e}>{e}</td>)}</tr>)}</tbody></table></div>}
              {props.secondaryMessage ? t(`graph_desc.${props.secondaryMessage}`).split('\n').map(s => <div key={s} className="infoModalText">{s}</div>) : undefined}
            </div>
          </div>
        : <div></div>
        }
      </div>
    </div>
  )
}


export default withTranslation()(InfoModal);