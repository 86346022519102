'use strict'; // code generated by pbf v3.2.0

// Regions ========================================

var Regions = exports.Regions = {};

Regions.read = function (pbf, end) {
    return pbf.readFields(Regions._readField, {regions: []}, end);
};
Regions._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.regions.push(Region.read(pbf, pbf.readVarint() + pbf.pos));
};
Regions.write = function (obj, pbf) {
    if (obj.regions) for (var i = 0; i < obj.regions.length; i++) pbf.writeMessage(1, Region.write, obj.regions[i]);
};

// Region ========================================

var Region = exports.Region = {};

Region.read = function (pbf, end) {
    return pbf.readFields(Region._readField, {region: "", nb_of_cars: 0, population: 0, cars_by_persons: 0, nb_of_households: 0, cars_by_household: 0, nb_of_internal_trips: 0, persons_by_household: 0, nb_of_trips_by_residents: 0, nb_of_trips_by_persons_5: 0, nb_of_non_moving_residents: 0, nb_of_interviewed_households: 0, trips_sm_am: null, trips_sm_24h: null, trips_by_period: null, age_distribution: null, trips_sm_mode_am: null, trips_sm_mode_24h: null, population_by_gender: null, car_household_distribution: null, region_population_rhythm: null, residents_life_rhythm: null, trips_by_residents_am: null, trips_by_residents_24h: null, trips_by_residents_mode_am: null, trips_by_residents_mode_24h: null, trips_by_residents_period: null, simplified_region_population_rhythm: null, simplified_residents_life_rhythm: null}, end);
};
Region._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.region = pbf.readString();
    else if (tag === 2) obj.nb_of_cars = pbf.readVarint(true);
    else if (tag === 3) obj.population = pbf.readVarint(true);
    else if (tag === 4) obj.cars_by_persons = pbf.readDouble();
    else if (tag === 5) obj.nb_of_households = pbf.readVarint(true);
    else if (tag === 6) obj.cars_by_household = pbf.readDouble();
    else if (tag === 7) obj.nb_of_internal_trips = pbf.readVarint(true);
    else if (tag === 8) obj.persons_by_household = pbf.readDouble();
    else if (tag === 9) obj.nb_of_trips_by_residents = pbf.readVarint(true);
    else if (tag === 10) obj.nb_of_trips_by_persons_5 = pbf.readDouble();
    else if (tag === 11) obj.nb_of_non_moving_residents = pbf.readVarint(true);
    else if (tag === 12) obj.nb_of_interviewed_households = pbf.readVarint(true);
    else if (tag === 13) obj.trips_sm_am = TripsPurpose.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 14) obj.trips_sm_24h = TripsPurpose.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 15) obj.trips_by_period = TripsPeriod.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 16) obj.age_distribution = AgeDistribution.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 17) obj.trips_sm_mode_am = TripsMode.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 18) obj.trips_sm_mode_24h = TripsMode.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 19) obj.population_by_gender = Gender.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 20) obj.car_household_distribution = CarDistribution.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 21) obj.region_population_rhythm = RegionsLifeRhythm.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 22) obj.residents_life_rhythm = ResidentsLifeRhythm.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 23) obj.trips_by_residents_am = TripsPurpose.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 24) obj.trips_by_residents_24h = TripsPurpose.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 25) obj.trips_by_residents_mode_am = TripsMode.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 26) obj.trips_by_residents_mode_24h = TripsMode.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 27) obj.trips_by_residents_period = TripsPeriod.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 28) obj.simplified_region_population_rhythm = SimplifiedLifeRhythm.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 29) obj.simplified_residents_life_rhythm = SimplifiedLifeRhythm.read(pbf, pbf.readVarint() + pbf.pos);
};
Region.write = function (obj, pbf) {
    if (obj.region) pbf.writeStringField(1, obj.region);
    if (obj.nb_of_cars) pbf.writeVarintField(2, obj.nb_of_cars);
    if (obj.population) pbf.writeVarintField(3, obj.population);
    if (obj.cars_by_persons) pbf.writeDoubleField(4, obj.cars_by_persons);
    if (obj.nb_of_households) pbf.writeVarintField(5, obj.nb_of_households);
    if (obj.cars_by_household) pbf.writeDoubleField(6, obj.cars_by_household);
    if (obj.nb_of_internal_trips) pbf.writeVarintField(7, obj.nb_of_internal_trips);
    if (obj.persons_by_household) pbf.writeDoubleField(8, obj.persons_by_household);
    if (obj.nb_of_trips_by_residents) pbf.writeVarintField(9, obj.nb_of_trips_by_residents);
    if (obj.nb_of_trips_by_persons_5) pbf.writeDoubleField(10, obj.nb_of_trips_by_persons_5);
    if (obj.nb_of_non_moving_residents) pbf.writeVarintField(11, obj.nb_of_non_moving_residents);
    if (obj.nb_of_interviewed_households) pbf.writeVarintField(12, obj.nb_of_interviewed_households);
    if (obj.trips_sm_am) pbf.writeMessage(13, TripsPurpose.write, obj.trips_sm_am);
    if (obj.trips_sm_24h) pbf.writeMessage(14, TripsPurpose.write, obj.trips_sm_24h);
    if (obj.trips_by_period) pbf.writeMessage(15, TripsPeriod.write, obj.trips_by_period);
    if (obj.age_distribution) pbf.writeMessage(16, AgeDistribution.write, obj.age_distribution);
    if (obj.trips_sm_mode_am) pbf.writeMessage(17, TripsMode.write, obj.trips_sm_mode_am);
    if (obj.trips_sm_mode_24h) pbf.writeMessage(18, TripsMode.write, obj.trips_sm_mode_24h);
    if (obj.population_by_gender) pbf.writeMessage(19, Gender.write, obj.population_by_gender);
    if (obj.car_household_distribution) pbf.writeMessage(20, CarDistribution.write, obj.car_household_distribution);
    if (obj.region_population_rhythm) pbf.writeMessage(21, RegionsLifeRhythm.write, obj.region_population_rhythm);
    if (obj.residents_life_rhythm) pbf.writeMessage(22, ResidentsLifeRhythm.write, obj.residents_life_rhythm);
    if (obj.trips_by_residents_am) pbf.writeMessage(23, TripsPurpose.write, obj.trips_by_residents_am);
    if (obj.trips_by_residents_24h) pbf.writeMessage(24, TripsPurpose.write, obj.trips_by_residents_24h);
    if (obj.trips_by_residents_mode_am) pbf.writeMessage(25, TripsMode.write, obj.trips_by_residents_mode_am);
    if (obj.trips_by_residents_mode_24h) pbf.writeMessage(26, TripsMode.write, obj.trips_by_residents_mode_24h);
    if (obj.trips_by_residents_period) pbf.writeMessage(27, TripsPeriod.write, obj.trips_by_residents_period);
    if (obj.simplified_region_population_rhythm) pbf.writeMessage(28, SimplifiedLifeRhythm.write, obj.simplified_region_population_rhythm);
    if (obj.simplified_residents_life_rhythm) pbf.writeMessage(29, SimplifiedLifeRhythm.write, obj.simplified_residents_life_rhythm);
};

// TripsPurpose ========================================

var TripsPurpose = exports.TripsPurpose = {};

TripsPurpose.read = function (pbf, end) {
    return pbf.readFields(TripsPurpose._readField, {exiting: null, entering: null, internal: null}, end);
};
TripsPurpose._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.exiting = Purpose.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 2) obj.entering = Purpose.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 3) obj.internal = Purpose.read(pbf, pbf.readVarint() + pbf.pos);
};
TripsPurpose.write = function (obj, pbf) {
    if (obj.exiting) pbf.writeMessage(1, Purpose.write, obj.exiting);
    if (obj.entering) pbf.writeMessage(2, Purpose.write, obj.entering);
    if (obj.internal) pbf.writeMessage(3, Purpose.write, obj.internal);
};

// Purpose ========================================

var Purpose = exports.Purpose = {};

Purpose.read = function (pbf, end) {
    return pbf.readFields(Purpose._readField, {home: 0, work: 0, other: 0, school: 0, leisure: 0, shopping: 0}, end);
};
Purpose._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.home = pbf.readVarint(true);
    else if (tag === 2) obj.work = pbf.readVarint(true);
    else if (tag === 3) obj.other = pbf.readVarint(true);
    else if (tag === 4) obj.school = pbf.readVarint(true);
    else if (tag === 5) obj.leisure = pbf.readVarint(true);
    else if (tag === 6) obj.shopping = pbf.readVarint(true);
};
Purpose.write = function (obj, pbf) {
    if (obj.home) pbf.writeVarintField(1, obj.home);
    if (obj.work) pbf.writeVarintField(2, obj.work);
    if (obj.other) pbf.writeVarintField(3, obj.other);
    if (obj.school) pbf.writeVarintField(4, obj.school);
    if (obj.leisure) pbf.writeVarintField(5, obj.leisure);
    if (obj.shopping) pbf.writeVarintField(6, obj.shopping);
};

// TripsPeriod ========================================

var TripsPeriod = exports.TripsPeriod = {};

TripsPeriod.read = function (pbf, end) {
    return pbf.readFields(TripsPeriod._readField, {exiting: null, entering: null, internal: null}, end);
};
TripsPeriod._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.exiting = Period.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 2) obj.entering = Period.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 3) obj.internal = Period.read(pbf, pbf.readVarint() + pbf.pos);
};
TripsPeriod.write = function (obj, pbf) {
    if (obj.exiting) pbf.writeMessage(1, Period.write, obj.exiting);
    if (obj.entering) pbf.writeMessage(2, Period.write, obj.entering);
    if (obj.internal) pbf.writeMessage(3, Period.write, obj.internal);
};

// Period ========================================

var Period = exports.Period = {};

Period.read = function (pbf, end) {
    return pbf.readFields(Period._readField, {am: 0, pm: 0, day: 0, night: 0, evening: 0}, end);
};
Period._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.am = pbf.readVarint(true);
    else if (tag === 2) obj.pm = pbf.readVarint(true);
    else if (tag === 3) obj.day = pbf.readVarint(true);
    else if (tag === 4) obj.night = pbf.readVarint(true);
    else if (tag === 5) obj.evening = pbf.readVarint(true);
};
Period.write = function (obj, pbf) {
    if (obj.am) pbf.writeVarintField(1, obj.am);
    if (obj.pm) pbf.writeVarintField(2, obj.pm);
    if (obj.day) pbf.writeVarintField(3, obj.day);
    if (obj.night) pbf.writeVarintField(4, obj.night);
    if (obj.evening) pbf.writeVarintField(5, obj.evening);
};

// TripsMode ========================================

var TripsMode = exports.TripsMode = {};

TripsMode.read = function (pbf, end) {
    return pbf.readFields(TripsMode._readField, {exiting: null, entering: null, internal: null}, end);
};
TripsMode._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.exiting = Mode.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 2) obj.entering = Mode.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 3) obj.internal = Mode.read(pbf, pbf.readVarint() + pbf.pos);
};
TripsMode.write = function (obj, pbf) {
    if (obj.exiting) pbf.writeMessage(1, Mode.write, obj.exiting);
    if (obj.entering) pbf.writeMessage(2, Mode.write, obj.entering);
    if (obj.internal) pbf.writeMessage(3, Mode.write, obj.internal);
};

// Mode ========================================

var Mode = exports.Mode = {};

Mode.read = function (pbf, end) {
    return pbf.readFields(Mode._readField, {car: 0, other: 0, bimode: 0, transit: 0, active: 0, other_transit: 0}, end);
};
Mode._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.car = pbf.readVarint(true);
    else if (tag === 2) obj.other = pbf.readVarint(true);
    else if (tag === 3) obj.bimode = pbf.readVarint(true);
    else if (tag === 4) obj.transit = pbf.readVarint(true);
    else if (tag === 6) obj.active = pbf.readVarint(true);
    else if (tag === 5) obj.other_transit = pbf.readVarint(true);
};
Mode.write = function (obj, pbf) {
    if (obj.car) pbf.writeVarintField(1, obj.car);
    if (obj.other) pbf.writeVarintField(2, obj.other);
    if (obj.bimode) pbf.writeVarintField(3, obj.bimode);
    if (obj.transit) pbf.writeVarintField(4, obj.transit);
    if (obj.active) pbf.writeVarintField(6, obj.active);
    if (obj.other_transit) pbf.writeVarintField(5, obj.other_transit);
};

// AgeDistribution ========================================

var AgeDistribution = exports.AgeDistribution = {};

AgeDistribution.read = function (pbf, end) {
    return pbf.readFields(AgeDistribution._readField, {age_65: 0, age_0_19: 0, age_20_34: 0, age_35_49: 0, age_50_64: 0}, end);
};
AgeDistribution._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.age_65 = pbf.readVarint(true);
    else if (tag === 2) obj.age_0_19 = pbf.readVarint(true);
    else if (tag === 3) obj.age_20_34 = pbf.readVarint(true);
    else if (tag === 4) obj.age_35_49 = pbf.readVarint(true);
    else if (tag === 5) obj.age_50_64 = pbf.readVarint(true);
};
AgeDistribution.write = function (obj, pbf) {
    if (obj.age_65) pbf.writeVarintField(1, obj.age_65);
    if (obj.age_0_19) pbf.writeVarintField(2, obj.age_0_19);
    if (obj.age_20_34) pbf.writeVarintField(3, obj.age_20_34);
    if (obj.age_35_49) pbf.writeVarintField(4, obj.age_35_49);
    if (obj.age_50_64) pbf.writeVarintField(5, obj.age_50_64);
};

// Gender ========================================

var Gender = exports.Gender = {};

Gender.read = function (pbf, end) {
    return pbf.readFields(Gender._readField, {male: 0, female: 0, undefined: 0}, end);
};
Gender._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.male = pbf.readVarint(true);
    else if (tag === 2) obj.female = pbf.readVarint(true);
    else if (tag === 3) obj.undefined = pbf.readVarint(true);
};
Gender.write = function (obj, pbf) {
    if (obj.male) pbf.writeVarintField(1, obj.male);
    if (obj.female) pbf.writeVarintField(2, obj.female);
    if (obj.undefined) pbf.writeVarintField(3, obj.undefined);
};

// CarDistribution ========================================

var CarDistribution = exports.CarDistribution = {};

CarDistribution.read = function (pbf, end) {
    return pbf.readFields(CarDistribution._readField, {car_0: 0, car_1: 0, car_2: 0, car_3: 0, car_4: 0}, end);
};
CarDistribution._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.car_0 = pbf.readVarint(true);
    else if (tag === 2) obj.car_1 = pbf.readVarint(true);
    else if (tag === 3) obj.car_2 = pbf.readVarint(true);
    else if (tag === 4) obj.car_3 = pbf.readVarint(true);
    else if (tag === 5) obj.car_4 = pbf.readVarint(true);
};
CarDistribution.write = function (obj, pbf) {
    if (obj.car_0) pbf.writeVarintField(1, obj.car_0);
    if (obj.car_1) pbf.writeVarintField(2, obj.car_1);
    if (obj.car_2) pbf.writeVarintField(3, obj.car_2);
    if (obj.car_3) pbf.writeVarintField(4, obj.car_3);
    if (obj.car_4) pbf.writeVarintField(5, obj.car_4);
};

// ResidentsLifeRhythm ========================================

var ResidentsLifeRhythm = exports.ResidentsLifeRhythm = {};

ResidentsLifeRhythm.read = function (pbf, end) {
    return pbf.readFields(ResidentsLifeRhythm._readField, {centre_ville: [], couronne_nord: [], couronne_sud: [], hors_territoire: [], laval: [], montreal_centre: [], montreal_est: [], montreal_ouest: [], rive_sud: []}, end);
};
ResidentsLifeRhythm._readField = function (tag, obj, pbf) {
    if (tag === 1) pbf.readPackedVarint(obj.centre_ville, true);
    else if (tag === 2) pbf.readPackedVarint(obj.couronne_nord, true);
    else if (tag === 3) pbf.readPackedVarint(obj.couronne_sud, true);
    else if (tag === 4) pbf.readPackedVarint(obj.hors_territoire, true);
    else if (tag === 5) pbf.readPackedVarint(obj.laval, true);
    else if (tag === 6) pbf.readPackedVarint(obj.montreal_centre, true);
    else if (tag === 7) pbf.readPackedVarint(obj.montreal_est, true);
    else if (tag === 8) pbf.readPackedVarint(obj.montreal_ouest, true);
    else if (tag === 9) pbf.readPackedVarint(obj.rive_sud, true);
};
ResidentsLifeRhythm.write = function (obj, pbf) {
    if (obj.centre_ville) pbf.writePackedVarint(1, obj.centre_ville);
    if (obj.couronne_nord) pbf.writePackedVarint(2, obj.couronne_nord);
    if (obj.couronne_sud) pbf.writePackedVarint(3, obj.couronne_sud);
    if (obj.hors_territoire) pbf.writePackedVarint(4, obj.hors_territoire);
    if (obj.laval) pbf.writePackedVarint(5, obj.laval);
    if (obj.montreal_centre) pbf.writePackedVarint(6, obj.montreal_centre);
    if (obj.montreal_est) pbf.writePackedVarint(7, obj.montreal_est);
    if (obj.montreal_ouest) pbf.writePackedVarint(8, obj.montreal_ouest);
    if (obj.rive_sud) pbf.writePackedVarint(9, obj.rive_sud);
};

// RegionsLifeRhythm ========================================

var RegionsLifeRhythm = exports.RegionsLifeRhythm = {};

RegionsLifeRhythm.read = function (pbf, end) {
    return pbf.readFields(RegionsLifeRhythm._readField, {centre_ville: [], couronne_nord: [], couronne_sud: [], laval: [], montreal_centre: [], montreal_est: [], montreal_ouest: [], rive_sud: []}, end);
};
RegionsLifeRhythm._readField = function (tag, obj, pbf) {
    if (tag === 1) pbf.readPackedVarint(obj.centre_ville, true);
    else if (tag === 2) pbf.readPackedVarint(obj.couronne_nord, true);
    else if (tag === 3) pbf.readPackedVarint(obj.couronne_sud, true);
    else if (tag === 4) pbf.readPackedVarint(obj.laval, true);
    else if (tag === 5) pbf.readPackedVarint(obj.montreal_centre, true);
    else if (tag === 6) pbf.readPackedVarint(obj.montreal_est, true);
    else if (tag === 7) pbf.readPackedVarint(obj.montreal_ouest, true);
    else if (tag === 8) pbf.readPackedVarint(obj.rive_sud, true);
};
RegionsLifeRhythm.write = function (obj, pbf) {
    if (obj.centre_ville) pbf.writePackedVarint(1, obj.centre_ville);
    if (obj.couronne_nord) pbf.writePackedVarint(2, obj.couronne_nord);
    if (obj.couronne_sud) pbf.writePackedVarint(3, obj.couronne_sud);
    if (obj.laval) pbf.writePackedVarint(4, obj.laval);
    if (obj.montreal_centre) pbf.writePackedVarint(5, obj.montreal_centre);
    if (obj.montreal_est) pbf.writePackedVarint(6, obj.montreal_est);
    if (obj.montreal_ouest) pbf.writePackedVarint(7, obj.montreal_ouest);
    if (obj.rive_sud) pbf.writePackedVarint(8, obj.rive_sud);
};

// SimplifiedLifeRhythm ========================================

var SimplifiedLifeRhythm = exports.SimplifiedLifeRhythm = {};

SimplifiedLifeRhythm.read = function (pbf, end) {
    return pbf.readFields(SimplifiedLifeRhythm._readField, {inside: [], outside: []}, end);
};
SimplifiedLifeRhythm._readField = function (tag, obj, pbf) {
    if (tag === 1) pbf.readPackedVarint(obj.inside, true);
    else if (tag === 2) pbf.readPackedVarint(obj.outside, true);
};
SimplifiedLifeRhythm.write = function (obj, pbf) {
    if (obj.inside) pbf.writePackedVarint(1, obj.inside);
    if (obj.outside) pbf.writePackedVarint(2, obj.outside);
};
