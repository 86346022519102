import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import colors from '../../../config/colors.json';
import highchartsConfig from '../../../config/HighchartsConfig.json';
import InfoModal from '../InfoModal.js';
import {pick} from 'lodash';


Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Open Sans'
    }
  }
})

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

class StackedDataComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredCell: false,
      hoveredGraph: null,
      infoModalOpen: false,
    }
    this._closeInfoModal = this._closeInfoModal.bind(this);
  }

  _closeInfoModal() {
    this.setState({infoModalOpen: false});
  }

  render() {
    const {t} = this.props;
    const sortOrder = {
      'centre_ville': 1,
      'montreal_centre': 2,
      'montreal_est': 3,
      'montreal_ouest': 4,
      'rive_sud': 5,
      'laval': 6,
      'couronne_nord': 7,
      'couronne_sud': 8,
      'hors_territoire': 9
    };
    const data = this.props.showOutOfTerritory ? this.props.data : pick(this.props.data, Object.keys(this.props.data).filter(r => r != 'hors_territoire'));
    return(
      <div className="chartSuperContainer">
        <div className="chartContainer">
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...highchartsConfig,
              "exporting": {
                "enabled": true,
                "buttons": {
                  "contextButton": {
                    "symbolStroke": "#000",
                    "theme": {
                      "fill": "rgba(0,0,0,0)"
                    },
                    "menuItems": [{
                      text: t('about_chart'),
                      onclick: () => this.setState({infoModalOpen: !this.state.infoModalOpen})
                    }, "separator", "printChart", "separator", "downloadPNG", "downloadSVG", "downloadCSV", "downloadXLS"]
                  }
                }
              },
              lang: {
                contextButtonTitle: t('chartContextMenu'),
                downloadPNG: t('downloadPNG'),
                downloadJPEG: t('downloadJPEG'),
                downloadPDF: t('downloadPDF'),
                downloadSVG: t('downloadSVG'),
                downloadCSV: t('downloadCSV'),
                downloadXLS: t('downloadXLS'),
                printChart: t('printChart')
              },
              chart: {
                type: 'column',
                backgroundColor: 'rgba(0,0,0,0)'
              },
              title: {
                text: t(this.props.title)
              },
              tooltip: {
                formatter() {
                  let output = `<span style=font-size:10px>${this.key}</span><br/><span style="color:${this.color}">●</span>\u00A0${this.series.name}: ${(Math.round(this.y / 100)*100).toLocaleString('fr')}`
                  return output
                }
              },
              xAxis: {
                categories: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27].map(h => `${h.toString().padStart(2,'0')}\u2009h`),
                title: {
                  text: t('hour_of_day')
                }
              },
              yAxis: {
                reversedStacks: false,
                title: {
                  text: t('unit.total.' + this.props.grouping)
                }
              },
              plotOptions: {
                column: {
                  stacking: 'normal'
                },
                borderRadius: 3,
                series: {
                  groupPadding: 0,
                  pointPadding: 0,
                  borderWidth: 1,
                  borderColor: '#bbb',
                  shadow: false
                }
              },
              legend: {
                itemHiddenStyle: {
                  color: '#B0B0B0'
                }
              },
              series: Object.keys(data).sort((region1,region2) => {return sortOrder[region1] - sortOrder[region2]}).map((region) => {
                return({
                  name: t(region),
                  data: data[region],
                  color: colors['regions'][region] ? colors['regions'][region] : colors['regions'][this.props.ra]
                })
              })
            }}
          />
          <InfoModal message={this.props.title} secondaryMessage={this.props.nested ? this.state.nestingKey : null} classSuffix={this.state.infoModalOpen ? 'visible' : 'hidden'} closeInfoModal={this._closeInfoModal} />
        </div>
      </div>
    )
  }
}
export default withTranslation()(StackedDataComponent);