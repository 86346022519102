import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import Modal from './GenericModal.js';
import './modal.css';
import Collapsible from 'react-collapsible';

class FAQModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didMount: false
    }
  }

  componentDidMount() {
    this.setState({didMount: true})
  }

  render() {
    const {t} = this.props;
    return(
      <Modal onBorderClick={this.props.onBorderClick}>
        <div className="genericModalContent">
          <div className="genericModalTitle">{t('faq')}</div>
          <div className="collapsibleContainer">
            <Collapsible triggerClassName="even" triggerOpenedClassName="even" trigger={t('faq.q.about_platform')} lazyRender={true}>
              <div className="faqAnswer">{t('faq.a.about_platform')}</div>
            </Collapsible>
            <Collapsible triggerClassName="odd" triggerOpenedClassName="odd" trigger={t('faq.q.about_survey')} lazyRender={true}>
              <div className="faqAnswer">{t('faq.a.about_survey')}</div>
            </Collapsible>
            <Collapsible triggerClassName="even" triggerOpenedClassName="even" trigger={t('faq.q.file_version')} lazyRender={true}>
              <div className="faqAnswer">{t('faq.a.file_version')}</div>
            </Collapsible>
            <Collapsible triggerClassName="odd" triggerOpenedClassName="odd" trigger={t('faq.q.sm_life_rhythm')} lazyRender={true}>
              <div className="faqAnswer">{t('faq.a.sm_life_rhythm')}</div>
            </Collapsible>
            <Collapsible triggerClassName="even" triggerOpenedClassName="even" trigger={t('faq.q.hidden_info')} lazyRender={true}>
              <div className="faqAnswer">{t('faq.a.hidden_info')}</div>
            </Collapsible>
          </div>
        </div>
      </Modal>
    )
  }

}

export default withTranslation()(FAQModal);