import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { TacoTable, DataType, Formatters } from 'react-taco-table';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
//import HC_exporting from 'highcharts/modules/exporting';
import colors from '../../../config/colors.json';
import highchartsConfig from '../../../config/HighchartsConfig.json';
import sortKeys from '../../../config/sortkeys.json';
import InfoModal from '../InfoModal.js';
import TableMenu from '../TableMenu.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCopy, faBars } from '@fortawesome/free-solid-svg-icons';


require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Open Sans'
    }
  }
})

class RadialDataComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredCell: false,
      hoveredGraph: null,
      infoModalOpen: false,
      nestingKey: this.props.nested ? Object.keys(props.data)[0] : null,
      menuOpen: false
    }
    this._closeInfoModal = this._closeInfoModal.bind(this);
    this._openInfoModal = this._openInfoModal.bind(this);
    this._closeMenu = this._closeMenu.bind(this);
  }

  _closeInfoModal() {
    this.setState({infoModalOpen: false});
  }

  _openInfoModal() {
    this.setState({infoModalOpen: true, menuOpen: false});
  }

  _closeMenu() {
    this.setState({menuOpen: false});
  }

  _customFormatter(numDecimals, value) {
    return (value.toFixed(numDecimals).toLocaleString('fr', {'minimumFractionDigits': 1,'maximumFractionDigits': 1}))
  }

  render() {
    const {t,usePercent,unit} = this.props;
    const data = this.props.nested ? this.props.data[this.state.nestingKey] : this.props.data;
    const sampleSize = this.props.nested ? this.props.validityData[this.state.nestingKey] : this.props.validityData;
    const grouping = this.props.grouping;
    console.log(data)
    return (
      <div className="chartSuperContainer">
        {this.props.nested ? <div className="buttonContainer">{Object.keys(this.props.data).map((k,i) => {
          return(
            <button className={`${k === this.state.nestingKey ? 'selected' : undefined} radialStateButton`} key={k} onClick={() => this.setState({nestingKey: k})}>{t(k + (this.props.shortNestMenu ? '.short' : ''))}</button>)}
          )}</div> : null}
        {this.props.useTables ? 
          <div className="panelContent">
            <div className="tableContainer">
              <InfoModal 
                message={this.props.title} 
                secondaryMessage={this.props.nested ? this.state.nestingKey : null} 
                grouping={`graph_desc.${this.props.grouping}`} 
                classSuffix={this.state.infoModalOpen ? 'visible' : 'hidden'} 
                closeInfoModal={this._closeInfoModal} 
                onClickOutside={this._closeInfoModalMenu}
              />
              {sampleSize < 30 ? <div className="panelContent">{t('non_significative_sample')}</div> :
                <div>
                  <div className="tableTitleContainer">
                    <div className="tableTitle">
                      <div>{this.props.nested ? t(this.props.title + '.' + this.state.nestingKey) : t(this.props.title)}</div>
                      <div className="sampleSizeMessage">{sampleSize ? (sampleSize + ' ' + t('population_size')) : null}</div>
                    </div>
                    <div className="tableMenuContainer">
                      <FontAwesomeIcon onClick={() => {this.setState({menuOpen: !this.state.menuOpen});}} color="#333333" icon={faBars} />
                      {this.state.menuOpen && <TableMenu closeMenu={this._closeMenu} openInfoModal={this._openInfoModal} /> }
                    </div>
                  </div>
                  <div className="tableContent">
                      <TacoTable 
                        columns={[
                          {
                            id: "title",
                            type: this.props.sortTitlesAsNumbers ? DataType.Number : DataType.string,
                            header: t(this.props.grouping),
                            sortValue: (cellData,rowData) => rowData.index
                          },
                          {
                            id: "values",
                            type: DataType.string,
                            header: this.props.usePercent ? t('unit.pct.' + this.props.unit) : t('unit.total.' + this.props.unit),
                            sortValue: (cellData,rowData) => {
                              return (parseFloat(cellData.replace(/\s/, '').replace(',','.')))
                            }
                          }
                        ]} 
                        data={sortKeys[this.props.title] ? sortKeys[this.props.title].map((d,i) => {
                          return ({
                            title: colors[this.props.grouping] ? t(d) : t(`${this.props.grouping}.${d}`),
                            values: this.props.usePercent ? ((Math.round((data[d]/Object.keys(data).map((k) => data[k]).reduce((a,b) => a+b)) * 1000)/10)).toLocaleString('fr', {'minimumFractionDigits': 1,'maximumFractionDigits': 1}) + ' %' : (Math.round(data[d] / 100)*100).toLocaleString('fr'),
                            index: i
                          })
                        }) : Object.keys(data).map((d,i) => {
                          return ({
                            title: colors[this.props.grouping] ? t(d) : t(`${this.props.grouping}.${d}`),
                            values: this.props.usePercent ? ((Math.round((data[d]/Object.keys(data).map((k) => data[k]).reduce((a,b) => a+b)) * 1000)/10)).toLocaleString('fr', {'minimumFractionDigits': 1,'maximumFractionDigits': 1}) + ' %' : (Math.round(data[d] / 100)*100).toLocaleString('fr'),
                            index: i
                          })})}
                      />
                    
                  </div>
                </div>
              }
            </div>
          </div> :
          <div className="chartContainer">
            {sampleSize < 30 ? <div className="panelContent"><span className="nonSignificativeValue">{t('non_significative_sample')}</span></div> :
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  ...(this.props.width && {"legend": {        
                    align: 'right',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    x: 0,
                    y: 100}
                  }),
                  "exporting": {
                    "enabled": true,
                    csv: {
                      columnHeaderFormatter: function(item, key) {
                        if (!key) {
                          return t(grouping)
                        }
                        return false
                      }
                    },
                    "buttons": {
                      "contextButton": {
                        "symbolStroke": "#000",
                        "theme": {
                          "fill": "rgba(0,0,0,0)"
                        },
                        "menuItems": [{
                          text: t('about_chart'),
                          onclick: () => this.setState({infoModalOpen: !this.state.infoModalOpen})
                        }, "separator", "printChart", "separator", "downloadPNG", "downloadSVG", "downloadCSV", "downloadXLS"]
                      }
                    }
                  },
                  "credits": {
                    "enabled": false
                  },
                  lang: {
                    contextButtonTitle: t('chartContextMenu'),
                    downloadPNG: t('downloadPNG'),
                    downloadJPEG: t('downloadJPEG'),
                    downloadPDF: t('downloadPDF'),
                    downloadSVG: t('downloadSVG'),
                    downloadCSV: t('downloadCSV'),
                    downloadXLS: t('downloadXLS'),
                    printChart: t('printChart')
                  },
                  chart: {
                    ...(this.props.width && {width: this.props.width, height: this.props.width}),
                    type: 'pie',
                    backgroundColor: 'rgba(0,0,0,0)',
                    events: {
                      click: (e) => {
                        this.setState({infoModalOpen: false})
                      }
                    }
                  },
                  plotOptions: {
                    pie: {
                      allowPointSelect: true,
                      cursor: 'pointer',
                      dataLabels: {
                        enabled: false,
                        style: {
                          fontSize: '12px'
                        }
                      },
                      showInLegend: true
                    }
                  },
                  title: {
                    text: this.props.nested ? t(this.props.title + '.' + this.state.nestingKey) : t(this.props.title)
                  },
                  subtitle: {
                    text: sampleSize ? (sampleSize + ' ' + t('population_size')) : null
                  },
                  tooltip: {
                    pointFormat: `<b>{${this.props.usePercent ? 'point.percentage' : 'point.y'}:.${this.props.usePercent ? '1' : '0'}f}${this.props.usePercent ? t('unit.pct.' + this.props.unit) : ' ' + t('unit.' + this.props.unit)}</b>`,
                    formatter() {
                      let output = `<span style=font-size:10px>${this.key}</span><br/><span style="color:${this.color}">●</span> ${usePercent ? (Math.round(this.percentage*10)/10).toLocaleString('fr', {'minimumFractionDigits': 1,'maximumFractionDigits': 1}) : (Math.round(this.y / 100)*100).toLocaleString('fr')}\u00A0${usePercent ? t('unit.pct.' + unit) : t('unit.' + unit)}`
                      return output
                    }
                  },
                  series: [{
                    name: t('unit.total.' + this.props.unit),
                    colorByPoint: true,
                    data: sortKeys[this.props.title] ? sortKeys[this.props.title].map((d,i) => {
                      return(
                        colors[this.props.grouping] ? {
                          name: t(d),
                          y: data[d],
                          color: colors[this.props.grouping][d]
                        } : {
                          name: t(`${this.props.grouping}.${d}`),
                          y: data[d],
                          color: colors['undefined'][i]
                        }
                      )
                    }) : Object.keys(data).filter(d => data[d]).map((d,i) => {
                      return(
                        colors[this.props.grouping] ? {
                          name: t(d),
                          y: data[d],
                          color: colors[this.props.grouping][d]
                        } : {
                          name: t(`${this.props.grouping}.${d}`),
                          y: data[d],
                          color: colors['undefined'][i]
                        }
                      )
                    })
                    ,
                    point: {
                      events: {
                        legendItemClick: function(e) {
                          this.slice();
                          return false;
                        },                
                        click: (e) => {
                          this.setState({infoModalOpen: false})
                        }
                      }
                    }
                  }]
                }}
              />
            }
            <InfoModal 
              message={this.props.title} 
              secondaryMessage={this.props.nested ? this.state.nestingKey : null} 
              classSuffix={this.state.infoModalOpen ? 'visible' : 'hidden'} 
              closeInfoModal={this._closeInfoModal} 
              grouping={`graph_desc.${this.props.grouping}`} 
            />
          </div>
        }
      </div>
    )
  }
}
export default withTranslation()(RadialDataComponent);