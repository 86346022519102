import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldow from "highcharts/modules/drilldown";
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import colors from '../../../config/colors.json';
import highchartsConfig from '../../../config/HighchartsConfig.json';



drilldow(Highcharts);
highchartsMore(Highcharts);
solidGauge(Highcharts);

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Open Sans'
    }
  }
})

class SingleLevelDrillableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredCell: false,
      hoveredGraph: null,
      drillLevel: 0
    }
    this.afterChartCreated = this.afterChartCreated.bind(this);
  }

  afterChartCreated(chart) {
    this.internalChart = chart;
    chart.renderer.button('< Retour aux parts modales',0, 50)
      .attr({
        zIndex: 3
      })
      .on('click', () => {
        this.setState({drillLevel: 0})
      })
      .add();
  }

  //Borrowed from https://stackoverflow.com/a/28056903/3262038
  hexToRGB(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  render() {
    const {t} = this.props;
    const chartOptions = [{
            exporting: highchartsConfig.exporting,
            lang: {
                  downloadPNG: t('downloadPNG'),
                  downloadJPEG: t('downloadJPEG'),
                  downloadPDF: t('downloadPDF'),
                  downloadSVG: t('downloadSVG'),
                  printChart: t('printChart')
            },
            chart: {
              type: 'pie',
              backgroundColor: 'rgba(0,0,0,0)',
              events: {

              }
            },
            title: {
              text: 'Parts modale (PPAM)'
            },
            xAxis: {
              type: 'category'
            },
            legend: {
              enabled: false
            },
            tooltip: {
              pointFormat: '<b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              series: {
                cursor: 'pointer',
                point: {
                  events: {
                    click: (e) => {if (e.point.name == 'Motorisés') this.setState({drillLevel: 1})}
                  }
                },
                borderWidth: 0,
                dataLabels: {
                  enabled: true
                }
              }
            },
            series: [{
              name: 'Déplacements',
              colorByPoint: true,
              data: [{
                name: 'Motorisés',
                y: 92.6,
                color: colors['mode_family']['motorized']
              }, {
                name: 'Non motorisés',
                y: 7.3,
                color: colors['mode_family']['non_motorized']
              }, {
                name: 'Autres',
                y: 0.1,
                color: colors['mode_family']['other'] 
              }]
            }]
          },{
              exporting: highchartsConfig.exporting,
              lang: {
                  downloadPNG: t('downloadPNG'),
                  downloadJPEG: t('downloadJPEG'),
                  downloadPDF: t('downloadPDF'),
                  downloadSVG: t('downloadSVG'),
                  printChart: t('printChart')
              },
              chart: {
                type: 'solidgauge',
                backgroundColor: 'rgba(0,0,0,0)'
              },
              title: {
                text: '% des déplacements motorisés'
              },
              tooltip: {
                borderWidth: 0,
                backgroundColor: 'none',
                shadow: false,
                style: {
                  fontSize: '14px'
                },
                pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}%</span>',
                positioner: function (labelWidth) {
                  return {
                    x: (this.chart.chartWidth - labelWidth) / 2,
                    y: (this.chart.plotHeight / 2) + 15
                  };
                }
              },
          
              pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{ // Car use
                  outerRadius: '100%',
                  innerRadius: '86%',
                  backgroundColor: this.hexToRGB(colors['mode']['car'],0.2),
                  borderWidth: 0
                }, { // Transit
                  outerRadius: '85%',
                  innerRadius: '71%',
                  backgroundColor: this.hexToRGB(colors['mode']['transit'],0.2),
                  borderWidth: 0
                }, { // Bimodal
                  outerRadius: '70%',
                  innerRadius: '56%',
                  backgroundColor: this.hexToRGB(colors['mode']['bimodal'],0.2),
                  borderWidth: 0
                }, { // Other
                  outerRadius: '55%',
                  innerRadius: '41%',
                  backgroundColor: this.hexToRGB(colors['mode']['other'],0.2),
                  borderWidth: 0
                }]
              },
          
              yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
              },
          
              plotOptions: {
                solidgauge: {
                  dataLabels: {
                    enabled: false
                  },
                  linecap: 'round',
                  stickyTracking: false,
                  rounded: true
                }
              },
          
              series: [{
                name: 'utilisant l\'auto',
                data: [{
                  color: this.hexToRGB(colors['mode']['car']),
                  radius: '100%',
                  innerRadius: '86%',
                  y: 66.1
                }]
              }, {
                name: 'utilisant le T.C.',
                data: [{
                  color: this.hexToRGB(colors['mode']['transit']),
                  radius: '85%',
                  innerRadius: '71%',
                  y: 22.2
                }]
              }, {
                name: 'bimodaux',
                data: [{
                  color: this.hexToRGB(colors['mode']['bimodal']),
                  radius: '70%',
                  innerRadius: '56%',
                  y: 3.5
                }]
              }, {
                name: 'autres',
                data: [{
                  color: this.hexToRGB(colors['mode']['other']),
                  radius: '55%',
                  innerRadius: '41%',
                  y: 7.9
                }]
              }]
            }]
    return(
      <div>
        {this.state.drillLevel == 0 ? <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions[0]}
        /> : null }
        {this.state.drillLevel == 1 ? <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions[1]}
          callback={this.afterChartCreated}
        /> : null }
      </div>
    )
  }
}
export default withTranslation()(SingleLevelDrillableComponent);
