import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import fr from '../../config/fr.json';
import en from '../../config/en.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  fr: fr
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr",
    //loadPath: '/locales/{{lng}}/{{lng}}.json',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;