import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import './modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faPercent, faChartPie, faTable} from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import Select from 'react-select';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Switch from 'react-switch';
import RadialDataComponent from '../graphs/RadialDataComponent.js';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didMount: false,
      checked: false,
      check: false
    }
  }

  componentDidMount() {
    this.setState({didMount: true})
  }

  render() {
    const {t} = this.props;

    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style}}
          title={t('previous')}
          onClick={onClick}
        />
      );
    }
    
    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style}}
          title={t('next')}
          onClick={onClick}
        />
      );
    }

    const customStyles = {
      control: (base, state) => ({
        ...base,
        fontSize: 14,
        background: "#fff",
        borderRadius: "4px",
        borderColor: state.isFocused ? "#fff" : "#fff",
        "&:hover": {
          borderColor: state.isFocused ? "#fff" : "#fff"
        }
      }),
      option: (styles, {data, isEnabled, isFocused, isSelected}) => {
        return {
          ...styles,
          fontSize: 14,
          backgroundColor: isFocused ? "#dadada" : "#fff",
          color: "#000",
          ':active': {
            ...styles[':active'],
            backgroundColor: "#fff"
          }
        }
      },
      input: (styles) => {
        return {
          fontSize: 12,
          ...styles,
          color: "#000"
        }
      },
      noOptionsMessage: (styles) => {
        return {
          backgroundColor: "#fff",
          color: "#000"
        }
      },
      singleValue: (styles, { data }) => ({ 
        ...styles, 
        color: "#000"
      }),
      placeholder: styles => ({ 
        ...styles, 
        color: "#000"
      }),
      menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0
      }),
      menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
      })
    }




    return(
      <div className="modalContainer" onClick={this.props.onBorderClick}>
        <div className="modal" onClick={(e) => {e.stopPropagation()}}>
          <div className="closeModalButton"><FontAwesomeIcon onClick={this.props.onBorderClick} title={t('close')} id="modalClose" color="#008e84" icon={faTimes} size="2x" /></div>
          <Slider 
            ref={c => (this.slider = c)} 
            className="modalSlider" 
            dots={true} 
            infinite={false}
            prevArrow={<SamplePrevArrow />}
            nextArrow={<SampleNextArrow />}
          >
            <div className="modalFlexContainer">
              <div className="introModal">
                <div className="logo">
                  <div id="logo_eod"></div>
                </div>
                {t('modal.intro').split('\n').map(s => <div className="introModalText">{s}</div>)}
                <div className="introModalTextComplement">{t('modal.intro_complement')}</div>
                <div className="goToData">
                  <button id="goToDataButton" onClick={() => this.slider.slickNext()}>{t('start_instructions')}</button>
                </div>
              </div>
              <div className="introModalFooter">
                <div className="avCenterAlign">
                  <div className="introModalFooterContent">
                    <div className="introModalFooterButtonContainer">
                      <button id="introModalFooterButton" onClick={this.props.onBorderClick}>{t('go_to_results')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modalFlexContainer">
              <div className="modalFlex">
                <div className="modalText">
                  <div className="avCenterAlign">
                    <div className="modalTextElement">
                      {t('modal.results')}
                    </div>
                    {t('modal.results_complement').split('\n').map(s => <div className="modalTextElementComplement">{s}</div>)}
                    {t('modal.results_desktop_complement').split('\n').map(s => <div className="modalTextElementComplement">{s}</div>)}
                    <div className="mobileOnly modalSelectorContainer" onClick={() => null}>
                      <div id="selectmenu"></div>
                    </div>
                  </div>
                </div>
                <div className="modalImg">
                  <div id="selectSS"></div>
                </div>
              </div>
              <div className="modalFooter">
                <div className="avCenterAlign">
                  <div className="modalFooterContent">
                    <div className="modalFooterText">
                      &nbsp;
                    </div>
                    <div className="modalFooterButtonContainer">
                      <button id="modalFooterButton" onClick={this.props.onBorderClick}>{t('go_to_results')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modalFlexContainer">
              <div className="modalFlex">
                <div className="modalText">
                  <div className="avCenterAlign">
                    {t('modal.options').split('\n').map(s => <div className="modalTextElement">{s}</div>)}
                    {t('modal.options_complement').split('\n').map(s => <div className="modalTextElementComplement">{s}</div>)}
                    <div className="mobileOnly modalSwitch">
                      <div className="panelSwitchContainer modalSwitchLabel">
                        <label className="panelSwitch">
                          <span className="panelSwitchLabel"><FontAwesomeIcon icon={faPercent} />&nbsp;</span>
                          <Switch 
                            onChange={() => { null }}
                            checked={this.state.checked}
                            onColor="#BEBEBE"
                            offColor="#BEBEBE"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={16}
                            width={40}
                            className="reactSwitch"
                            disabled
                          />
                          <span className="panelSwitchLabel text">&nbsp;&#931;</span>
                        </label>
                        <label className="panelSwitch modalSwitchLabel">
                          <span className="panelSwitchLabel"><FontAwesomeIcon icon={faChartPie} />&nbsp;</span>
                          <Switch 
                            onChange={() => { null }}
                            checked={this.state.check}
                            onColor="#BEBEBE"
                            offColor="#BEBEBE"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={16}
                            width={40}
                            className="reactSwitch"
                            disabled
                          />
                          <span className="panelSwitchLabel">&nbsp;<FontAwesomeIcon icon={faTable} /></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modalImg">
                  <div className="vCenterAlign">
                    <div className="panelSwitchContainer modalSwitchLabel">
                      <label className="panelSwitch">
                        <span className="panelSwitchLabel"><FontAwesomeIcon icon={faPercent} />&nbsp;</span>
                        <Switch 
                          onChange={() => { this.setState({checked: !this.state.checked}) }}
                          checked={this.state.checked}
                          onColor="#BEBEBE"
                          offColor="#BEBEBE"
                          handleDiameter={20}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={16}
                          width={40}
                          className="reactSwitch"
                        />
                        <span className="panelSwitchLabel text">&nbsp;&#931;</span>
                      </label>
                      <label className="panelSwitch modalSwitchLabel">
                        <span className="panelSwitchLabel"><FontAwesomeIcon icon={faChartPie} />&nbsp;</span>
                        <Switch 
                          onChange={() => { this.setState({check: !this.state.check}) }}
                          checked={this.state.check}
                          onColor="#BEBEBE"
                          offColor="#BEBEBE"
                          handleDiameter={20}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={16}
                          width={40}
                          className="reactSwitch"
                        />
                        <span className="panelSwitchLabel">&nbsp;<FontAwesomeIcon icon={faTable} /></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalFooter">
                <div className="avCenterAlign">
                  <div className="modalFooterContent">
                    <div className="modalFooterText">
                      &nbsp;
                    </div>
                    <div className="modalFooterButtonContainer">
                      <button id="modalFooterButton" onClick={this.props.onBorderClick}>{t('go_to_results')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modalFlexContainer">
              <div className="modalFlex">
                <div className="modalText">
                  <div className="avCenterAlign">
                    <div className="modalTextElementComplement">
                      <span className="desktopOnly">1.&nbsp;</span>{t('modal.chart_option_p1')}
                    </div>
                    <div className="modalTextElementComplement">
                      <span className="desktopOnly">2.&nbsp;</span>{t('modal.chart_option_p2')}
                    </div>
                    <div className="modalTextElementComplement">
                      <span className="desktopOnly">3.&nbsp;</span>{t('modal.chart_option_p3')}
                    </div>
                  </div>
                </div>
                <div className="modalImg">
                  <div id="chartSS"></div>
                </div>
              </div>
              <div className="modalFooter">
                <div className="avCenterAlign">
                  <div className="modalFooterContent">
                    <div className="modalFooterText">
                      &nbsp;
                    </div>
                    <div className="modalFooterButtonContainer">
                      <button id="modalFooterButton" onClick={this.props.onBorderClick}>{t('go_to_results')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modalFlexContainer">
              <div className="modalFlex">
                <div className="modalText">
                  <div className="avCenterAlign">
                    {t('modal.about').split('\n').map(s => <div className="modalTextElement">{s}</div>)}
                    {t('modal.about_complement').split('\n').map(s => <div className="modalTextElementComplement">{s}</div>)}
                  </div>
                  <div className="mobileOnly modalMenuElement">
                  </div>
                </div>
                <div className="modalImg">
                  <div id="menuSS"></div>
                </div>
              </div>
              <div className="modalFooter">
                <div className="avCenterAlign">
                  <div className="modalFooterContent">
                    <div className="modalFooterText">
                      &nbsp;
                    </div>
                    <div className="modalFooterButtonContainer">
                      <button id="modalFooterButton" onClick={this.props.onBorderClick}>{t('go_to_results')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    )
  }

}
export default withTranslation()(Modal);