'use strict'; // code generated by pbf v3.2.0

// RegionsValidity ========================================

var RegionsValidity = exports.RegionsValidity = {};

RegionsValidity.read = function (pbf, end) {
    return pbf.readFields(RegionsValidity._readField, {regions: []}, end);
};
RegionsValidity._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.regions.push(Region.read(pbf, pbf.readVarint() + pbf.pos));
};
RegionsValidity.write = function (obj, pbf) {
    if (obj.regions) for (var i = 0; i < obj.regions.length; i++) pbf.writeMessage(1, Region.write, obj.regions[i]);
};

// Region ========================================

var Region = exports.Region = {};

Region.read = function (pbf, end) {
    return pbf.readFields(Region._readField, {region: "", trips_sm_am: null, trips_sm_24h: null, trips_by_period: null, age_distribution: 0, trips_sm_mode_am: null, trips_sm_mode_24h: null, trips_by_residents_am: null, trips_by_residents_24h: null, trips_by_residents_mode_am: null, trips_by_residents_mode_24h: null, trips_by_residents_period: null}, end);
};
Region._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.region = pbf.readString();
    else if (tag === 13) obj.trips_sm_am = SubCategory.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 14) obj.trips_sm_24h = SubCategory.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 15) obj.trips_by_period = SubCategory.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 16) obj.age_distribution = pbf.readVarint(true);
    else if (tag === 17) obj.trips_sm_mode_am = SubCategory.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 18) obj.trips_sm_mode_24h = SubCategory.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 23) obj.trips_by_residents_am = SubCategory.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 24) obj.trips_by_residents_24h = SubCategory.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 25) obj.trips_by_residents_mode_am = SubCategory.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 26) obj.trips_by_residents_mode_24h = SubCategory.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 27) obj.trips_by_residents_period = SubCategory.read(pbf, pbf.readVarint() + pbf.pos);
};
Region.write = function (obj, pbf) {
    if (obj.region) pbf.writeStringField(1, obj.region);
    if (obj.trips_sm_am) pbf.writeMessage(13, SubCategory.write, obj.trips_sm_am);
    if (obj.trips_sm_24h) pbf.writeMessage(14, SubCategory.write, obj.trips_sm_24h);
    if (obj.trips_by_period) pbf.writeMessage(15, SubCategory.write, obj.trips_by_period);
    if (obj.age_distribution) pbf.writeVarintField(16, obj.age_distribution);
    if (obj.trips_sm_mode_am) pbf.writeMessage(17, SubCategory.write, obj.trips_sm_mode_am);
    if (obj.trips_sm_mode_24h) pbf.writeMessage(18, SubCategory.write, obj.trips_sm_mode_24h);
    if (obj.trips_by_residents_am) pbf.writeMessage(23, SubCategory.write, obj.trips_by_residents_am);
    if (obj.trips_by_residents_24h) pbf.writeMessage(24, SubCategory.write, obj.trips_by_residents_24h);
    if (obj.trips_by_residents_mode_am) pbf.writeMessage(25, SubCategory.write, obj.trips_by_residents_mode_am);
    if (obj.trips_by_residents_mode_24h) pbf.writeMessage(26, SubCategory.write, obj.trips_by_residents_mode_24h);
    if (obj.trips_by_residents_period) pbf.writeMessage(27, SubCategory.write, obj.trips_by_residents_period);
};

// SubCategory ========================================

var SubCategory = exports.SubCategory = {};

SubCategory.read = function (pbf, end) {
    return pbf.readFields(SubCategory._readField, {exiting: 0, entering: 0, internal: 0}, end);
};
SubCategory._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.exiting = pbf.readVarint(true);
    else if (tag === 2) obj.entering = pbf.readVarint(true);
    else if (tag === 3) obj.internal = pbf.readVarint(true);
};
SubCategory.write = function (obj, pbf) {
    if (obj.exiting) pbf.writeVarintField(1, obj.exiting);
    if (obj.entering) pbf.writeVarintField(2, obj.entering);
    if (obj.internal) pbf.writeVarintField(3, obj.internal);
};
