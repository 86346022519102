import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import './modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class GenericModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didMount: false
    }
  }

  componentDidMount() {
    this.setState({didMount: true})
  }

  render() {
    const {t} = this.props;
    return(
      <div className="modalContainer" onClick={this.props.onBorderClick}>
        <div className="modal" onClick={(e) => {e.stopPropagation()}}>
          <div className="genericModalContainer">
            <span className="genericCloseModalButton"><FontAwesomeIcon onClick={this.props.onBorderClick} title={t('close')} id="modalClose" color="#008e84" icon={faTimes} size="2x" /></span>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }

}

export default withTranslation()(GenericModal);