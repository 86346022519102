import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import './panel.css';
import * as d3 from 'd3';
import { TacoTable, DataType, Formatters } from 'react-taco-table';
import Switch from 'react-switch';
import RadialDataComponent from './graphs/RadialDataComponent.js';
import StackedDataComponent from './graphs/StackedDataComponent.js';
import SingleLevelDrillableComponent from './graphs/SingleLevelDrillableComponent.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faTable, faPercent, faTimes} from '@fortawesome/free-solid-svg-icons';
import Collapsible from 'react-collapsible';
import municipalitiesByRegion from '../../data/municipalities_by_region.json';

const regionDictionary = {
  "1": "centre_ville",
  "2": "montreal_centre",
  "3": "montreal_est",
  "4": "montreal_ouest",
  "5": "rive_sud",
  "6": "laval",
  "7": "couronne_nord",
  "8": "couronne_sud"
}

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didMount: false,
      hoveredCell: false,
      hoveredRegion: null
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({didMount: true}), 1);
  }


  buildValue(hoveredCell) {
    console.log(hoveredCell);
    //const {radius, angle, angle0} = hoveredCell;
    //const truedAngle = (angle + angle0) / 2;
    //return {
    //  x: radius * Math.cos(truedAngle),
    //  y: radius * Math.sin(truedAngle)
    //};
  }

  roundValue(value,precision = 2, padding = false) {
    return (Math.round(value * Math.pow(10,precision))/Math.pow(10,precision)).toLocaleString('fr', {'minimumFractionDigits': precision,'maximumFractionDigits': precision});
  }

  _computeRLR(rlr,population,simplified=false,usePercent = false) {
    if (simplified) {
      const nbOutOfRegion = rlr['outside'];
      const pctOutOfRegion = nbOutOfRegion.map(r => r/population);
      var max = pctOutOfRegion[0];
      var maxIndex = 0;
      for (var i = 1; i < pctOutOfRegion.length; i++) {
        if (pctOutOfRegion[i] > max) {
          maxIndex = i;
          max = pctOutOfRegion[i];
        }
      }
      if (usePercent) {
        return([maxIndex+1,Math.round((pctOutOfRegion[maxIndex]*100)*10)/10])
      } else {
        return([maxIndex+1,Math.round(nbOutOfRegion[maxIndex]/100)*100])
      }
    } else {
      //For every hours, get how many residents are in/out of the region
      const outOfRegionRegions = Object.keys(rlr).filter(region => region != regionDictionary[this.props.sm.properties.ra]);
      const currentRegion = regionDictionary[this.props.sm.properties.ra];
      let nbOutOfRegion = [];
      for (let i = 0; i <= 27; i++) {
        let hourlySum = 0;
        outOfRegionRegions.forEach(region => {
          hourlySum += rlr[region][i];
        })
        nbOutOfRegion.push(hourlySum);
      }
      let pctOutOfRegion = [];
      for (let i = 0; i <= 27; i++) {
        pctOutOfRegion.push(nbOutOfRegion[i]/population);
      }
  
      //Borrowed from https://stackoverflow.com/questions/11301438/return-index-of-greatest-value-in-an-array/11301464
      var max = pctOutOfRegion[0];
      var maxIndex = 0;
      for (var i = 1; i < pctOutOfRegion.length; i++) {
        if (pctOutOfRegion[i] > max) {
          maxIndex = i;
          max = pctOutOfRegion[i];
        }
      }
  
      const mostVisitedRegions = outOfRegionRegions.sort((a,b) => rlr[b][maxIndex] - rlr[a][maxIndex]).slice(0,3);
      const mostVisitedRegionValues = mostVisitedRegions.map(region => rlr[region][maxIndex]);
      
  
      if (usePercent) {
        return([maxIndex+1,Math.round((pctOutOfRegion[maxIndex]*100)*10)/10,mostVisitedRegions,mostVisitedRegionValues.map(v => Math.round(((v/population)*1000))/10)])
      } else {
        return([maxIndex+1,(Math.round(nbOutOfRegion[maxIndex]/100)*100),mostVisitedRegions,mostVisitedRegionValues.map(v => (Math.round(v/100))*100)])
      }
    }
  }

  _computeRPR(rpr,population,simplified=false,usePercent=false) {
    //For every hours, get how many residents are in/out of the region
    const outOfRegionRegions = Object.keys(rpr).filter(region => region != regionDictionary[this.props.sm.properties.ra]);
    const currentRegion = regionDictionary[this.props.sm.properties.ra];
    let nbFromOutOfRegion = [];
    for (let i = 0; i <= 27; i++) {
      let hourlySum = 0;
      outOfRegionRegions.forEach(region => {
        hourlySum += rpr[region][i];
      })
      nbFromOutOfRegion.push(hourlySum);
    }
    let regionPopulation = [];
    for (let i = 0; i <= 27; i++) {
      let hourlySum = 0;
      Object.keys(rpr).forEach(region => {
        hourlySum += rpr[region][i];
      })
      regionPopulation.push(hourlySum);
    }
    let pctFromOutOfRegion = [];
    for (let i = 0; i <= 27; i++) {
      pctFromOutOfRegion.push(nbFromOutOfRegion[i]/regionPopulation[i]);
    }

    //Borrowed from https://stackoverflow.com/questions/11301438/return-index-of-greatest-value-in-an-array/11301464
    var max = regionPopulation[0];
    var maxIndex = 0;
    for (var i = 1; i < regionPopulation.length; i++) {
      if (regionPopulation[i] > max) {
        maxIndex = i;
        max = regionPopulation[i];
      }
    }

    const mostVisitedRegions = outOfRegionRegions.sort((a,b) => rpr[b][maxIndex] - rpr[a][maxIndex]).slice(0,3);
    const mostVisitedRegionValues = mostVisitedRegions.map(region => rpr[region][maxIndex]);

    var min = regionPopulation[0];
    var minIndex = 0;
    for (var i = 1; i < regionPopulation.length; i++) {
      if (regionPopulation[i] < min) {
        minIndex = i;
        min = regionPopulation[i];
      }
    }
    const leastVisitedRegions = outOfRegionRegions.sort((a,b) => rpr[b][minIndex] - rpr[a][minIndex]).slice(0,3);
    const leastVisitedRegionValues = leastVisitedRegions.map(region => rpr[region][minIndex]);

    if (usePercent) {
      return({
        max: [maxIndex+1,Math.round(regionPopulation[maxIndex]/100)*100,Math.round((100 - (pctFromOutOfRegion[maxIndex]*100))*10)/10,mostVisitedRegions,mostVisitedRegionValues.map(v => Math.round(((v/regionPopulation[maxIndex])*1000))/10)],
        min: [minIndex+1,Math.round(regionPopulation[minIndex]/100)*100,Math.round((100 - (pctFromOutOfRegion[minIndex]*100))*10)/10,leastVisitedRegions,leastVisitedRegionValues.map(v => Math.round(((v/regionPopulation[minIndex])*1000))/10)]
      })
    } else {
      return({
        max: [maxIndex+1,Math.round(regionPopulation[maxIndex]/100)*100,Math.round((regionPopulation[maxIndex] - nbFromOutOfRegion[maxIndex])/100)*100,mostVisitedRegions,mostVisitedRegionValues.map(v => Math.round(v/100)*100)],
        min: [minIndex+1,Math.round(regionPopulation[minIndex]/100)*100,Math.round((regionPopulation[minIndex] - nbFromOutOfRegion[minIndex])/100)*100,leastVisitedRegions,leastVisitedRegionValues.map(v => Math.round(v/100)*100)]
      })
    }
  }

  render () {
    const {t} = this.props;
    //This is a dirty hack because Highcharts (or the very least our StackedDataComponent) seems to be able to alter the data that is fed by the parent.
    //Doing a parse/stringify breaks the link between this component and the data that is fed from upstream, which negates the bug. Still, this is weiiiird
    const data = JSON.parse(JSON.stringify(this.props.data));
    const validityData = JSON.parse(JSON.stringify(this.props.validityData));
    const rlr  = parseInt(this.props.data.region) < 100 ? this._computeRLR(data.residents_life_rhythm,data.population,false,this.props.usePercent) : this._computeRLR(data.simplified_residents_life_rhythm,data.population,true,this.props.usePercent);
    const rpr  = parseInt(this.props.data.region) < 100 ? this._computeRPR(data.region_population_rhythm,data.population,false,this.props.usePercent) : this._computeRPR(data.simplified_region_population_rhythm,data.population,true,this.props.usePercent);
    const has_municipalities = [2,3,4,5,6,7,8].includes(this.props.sm.id);
    const has_boroughs = [1,2,3,4].includes(this.props.sm.id);
    return (
      <div className={`panelContainer fade-in ${this.state.didMount && 'visible'}`} onClick={this.props.onBorderClick}>
        <div className={`panel fade-in ${this.state.didMount && 'visible'}`} onClick={(e) => {e.stopPropagation()}}>
          <div className={`smHeader`}>
            <button className="panelHeaderButton" onClick={this.props.onBorderClick} title={t('close_panel')}><FontAwesomeIcon icon={faTimes} size="2x" /></button>
            <div className="panelSwitchContainer">
              <div className="switch-field">
                <input type="radio" id="radio-percent" name="switch-pct" value="percent" onChange={this.props.switchUsePercent} checked={this.props.usePercent} />
                <label title={t('show_percent')} htmlFor="radio-percent"><FontAwesomeIcon icon={faPercent} /></label>
                <input type="radio" id="radio-absolute" name="switch-abs" value="absolute" onChange={this.props.switchUseAbsolute} checked={!this.props.usePercent} />
                <label title={t('show_absolute')} htmlFor="radio-absolute">&nbsp;&#931;</label>
              </div>
              <div className="switch-field">
                <input type="radio" id="radio-chart" name="switch-chart" value="chart" onChange={this.props.switchUseGraphs} checked={!this.props.useTables} />
                <label title={t('show_table')} htmlFor="radio-chart"><FontAwesomeIcon icon={faChartPie} /></label>
                <input type="radio" id="radio-table" name="switch-table" value="table" onChange={this.props.switchUseTables} checked={this.props.useTables} />
                <label title={t('show_graph')} htmlFor="radio-table"><FontAwesomeIcon icon={faTable} /></label>
              </div>
            </div>
          </div>
          <div className="collapsibleContainer">
            <Collapsible triggerClassName="even" triggerOpenedClassName="even" trigger={t('region_summary')} lazyRender={true}>
              <div className="panelContent chartSuperContainer">
                <table>
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("population")}</td>
                      <td>{this.roundValue(data["population"],0)}</td>
                    </tr>
                    <tr>
                      <td>{t("nb_of_households")}</td>
                      <td>{this.roundValue(data["nb_of_households"],0)}</td>
                    </tr>
                    <tr>
                      <td>{t("nb_of_interviewed_households")}</td>
                      <td>{this.roundValue(data["nb_of_interviewed_households"],0)}</td>
                    </tr>
                    <tr>
                      <td>{t("pct_of_interviewed_households")}</td>
                      <td>{this.roundValue(data["nb_of_interviewed_households"]/data["nb_of_households"]*100,1)}%</td>
                    </tr>
                    <tr>
                      <td>{t("nb_of_cars")}</td>
                      <td>{this.roundValue(data["nb_of_cars"],0)}</td>
                    </tr>
                    <tr>
                      <td>{t("cars_by_household")}</td>
                      <td>{this.roundValue(data["cars_by_household"],1)}</td>
                    </tr>
                    <tr>
                      <td>{t("persons_by_household")}</td>
                      <td>{this.roundValue(data["persons_by_household"],1)}</td>
                    </tr>
                    <tr>
                      <td>{t("nb_of_non_moving_residents")}</td>
                      <td>{this.roundValue(data["nb_of_non_moving_residents"],0)}</td>
                    </tr>
                    <tr>
                      <td>{t("nb_of_trips_by_residents")}</td>
                      <td>{this.roundValue(data["nb_of_trips_by_residents"],0)}</td>
                    </tr>
                    <tr>
                      <td>{t("nb_of_trips_by_persons_5")}</td>
                      <td>{this.roundValue(data["nb_of_trips_by_persons_5"],1)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <RadialDataComponent 
                data={data.age_distribution}
                validityData={validityData['age_distribution']}
                useTables={this.props.useTables}
                usePercent={this.props.usePercent}
                title="age_distribution"
                grouping="age_group"
                unit="persons"
                sortTitlesAsNumbers={true}
              />
              <RadialDataComponent
                data={data.car_household_distribution}
                validityData={validityData['car_household_distribution']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="car_household_distribution"
                grouping="nb_of_cars"
                unit="households"
                sortTitlesAsNumbers={true}
              />
            </Collapsible>
            <Collapsible triggerClassName="odd" triggerOpenedClassName="odd" trigger={t('resident_activities')} lazyRender={true}>
              <RadialDataComponent
                data={data.trips_by_residents_period}
                validityData={validityData['trips_by_residents_period']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="trips_by_residents_period"
                grouping="trip_period"
                unit="trips"
                nested={true}
                shortNestMenu={this.props.windowWidth < 1200}
              />
              <RadialDataComponent
                data={data.trips_by_residents_am}
                validityData={validityData['trips_by_residents_am']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="trips_by_residents_am"
                grouping="trip_purpose"
                unit="trips"
                nested={true}
                shortNestMenu={this.props.windowWidth < 1200}
              />
              <RadialDataComponent
                data={data.trips_by_residents_24h}
                validityData={validityData['trips_by_residents_24h']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="trips_by_residents_24h"
                grouping="trip_purpose"
                unit="trips"
                nested={true}
                shortNestMenu={this.props.windowWidth < 1200}
              />
              <RadialDataComponent
                data={data.trips_by_residents_mode_am}
                validityData={validityData['trips_by_residents_mode_am']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="trips_by_residents_mode_am_share"
                grouping="trip_mode"
                unit="trips"
                nested={true}
                shortNestMenu={this.props.windowWidth < 1200}
              />
              <RadialDataComponent
                data={data.trips_by_residents_mode_24h}
                validityData={validityData['trips_by_residents_mode_24h']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="trips_by_residents_mode_24h_share"
                grouping="trip_mode"
                unit="trips"
                nested={true}
                shortNestMenu={this.props.windowWidth < 1200}
              />
              {this.props.useTables ? <div className="chartSuperContainer">
                  <div className="tableTitleContainer">
                    <div className="tableTitle">
                      {t('residents_life_rhythm')}
                    </div>
                  </div>
                  <div className="textExplanationContainer">
                    <div className="textExplanation">
                      {parseInt(this.props.data.region) < 100 ? t('residents_life_rhythm_text', {
                        maxNbOfResidentsOutside: rlr[1].toLocaleString('fr'),
                        unit: this.props.usePercent ? t('unit.pct.residents') : t('unit.residents_text'),
                        time: (rlr[0] == 12 || rlr[0] == 24) ? t(rlr[0] + '_00') : rlr[0]+'\u2009h\u200900',
                        sector1: t(rlr[2][0]).toLocaleString('fr'),
                        sector2: t(rlr[2][1]).toLocaleString('fr'),
                        sector3: t(rlr[2][2]).toLocaleString('fr'),
                        sector1_nb: rlr[3][0].toLocaleString('fr'),
                        sector2_nb: rlr[3][1].toLocaleString('fr'),
                        sector3_nb: rlr[3][2].toLocaleString('fr')
                      }) : t('simplified_residents_life_rhythm_text', {
                        maxNbOfResidentsOutside: rlr[1].toLocaleString('fr'),
                        unit: this.props.usePercent ? t('unit.pct.residents') : t('unit.residents_text'),
                        time: (rlr[0] == 12 || rlr[0] == 24) ? t(rlr[0] + '_00') : rlr[0]+'\u2009h\u200900',
                      })}
                    </div>
                  </div>
                </div> :
                <StackedDataComponent
                  data={parseInt(this.props.data.region) < 100 ? data.residents_life_rhythm : data.simplified_residents_life_rhythm}
                  useTables={this.props.useTables}
                  title={parseInt(this.props.data.region) < 100 ? "residents_life_rhythm" : "simplified_residents_life_rhythm"} 
                  grouping="residents"
                  usePercent={this.props.usePercent}
                  sorting="region"
                  showOutOfTerritory={true}
                  ra={this.props.sm.properties.ra}
                /> 
              }
            </Collapsible>
            <Collapsible triggerClassName="even" triggerOpenedClassName="even" trigger={t('region_activities')} lazyRender={true}>
              <RadialDataComponent
                data={data.trips_by_period}
                validityData={validityData['trips_by_period']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="trips_by_period"
                grouping="trip_period"
                unit="trips"
                nested={true}
                shortNestMenu={this.props.windowWidth < 1200}
              />
              <RadialDataComponent
                data={data.trips_sm_am}
                validityData={validityData['trips_sm_am']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="trips_sm_am"
                grouping="trip_purpose"
                unit="trips"
                nested={true}
                shortNestMenu={this.props.windowWidth < 1200}
              />
              <RadialDataComponent
                data={data.trips_sm_24h}
                validityData={validityData['trips_sm_24h']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="trips_sm_24h"
                grouping="trip_purpose"
                unit="trips"
                nested={true}
                shortNestMenu={this.props.windowWidth < 1200}
              />
              <RadialDataComponent
                data={data.trips_sm_mode_am}
                validityData={validityData['trips_sm_mode_am']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="trips_sm_mode_am_share"
                grouping="trip_mode"
                unit="trips"
                nested={true}
                shortNestMenu={this.props.windowWidth < 1200}
              />
              <RadialDataComponent
                data={data.trips_sm_mode_24h}
                validityData={validityData['trips_sm_mode_24h']}
                useTables={this.props.useTables} 
                usePercent={this.props.usePercent}
                title="trips_sm_mode_24h_share"
                grouping="trip_mode"
                unit="trips"
                nested={true}
                shortNestMenu={this.props.windowWidth < 1200}
              />
              {this.props.useTables ? <div className="chartSuperContainer">
                  <div className="tableTitleContainer">
                    <div className="tableTitle">
                      {t('region_population_rhythm')}
                    </div>
                  </div>
                  <div className="textExplanationContainer">
                    <div className="textExplanation">
                      {t('region_population_rhythm_text1', {
                        minNumberOfPersons: rpr['min'][1].toLocaleString('fr'),
                        personUnit:         t('unit.persons_text'),
                        minTime:            (rpr['min'][0] == 12 || rpr['min'][0] == 24) ? t(rpr['min'][0] + '_00') : (rpr['min'][0] + '').padStart(2,'0')+'\u2009h',
                        maxNumberOfPersons: rpr['max'][1].toLocaleString('fr'),
                        maxTime:            (rpr['max'][0] == 12 || rpr['max'][0] == 24) ? t(rpr['max'][0] + '_00') : (rpr['max'][0] + '').padStart(2,'0')+'\u2009h',
                      })}
                    </div>
                  </div>
                  <div className="textExplanationContainer">
                    <div className="textExplanation">
                      {parseInt(this.props.data.region) < 100 ? t('region_population_rhythm_text2', {
                        nbOfResidents: rpr['min'][2].toLocaleString('fr'),
                        unit:          this.props.usePercent ? t('unit.pct.persons') : t('unit.persons_text'),
                        sector1:       t(rpr['min'][3][0]).toLocaleString('fr'),
                        sector2:       t(rpr['min'][3][1]).toLocaleString('fr'),
                        sector3:       t(rpr['min'][3][2]).toLocaleString('fr'),
                        sector1_nb:    rpr['min'][4][0].toLocaleString('fr'),
                        sector2_nb:    rpr['min'][4][1].toLocaleString('fr'),
                        sector3_nb:    rpr['min'][4][2].toLocaleString('fr'),
                      }) : t('simplified_region_population_rhythm_text', {
                        nbOfResidents: rpr['min'][2].toLocaleString('fr'),
                        unit:          this.props.usePercent ? t('unit.pct.persons') : t('unit.persons_text'),
                        sector1_nb:    rpr['min'][4][0].toLocaleString('fr'),
                        sector2_nb:    rpr['min'][4][1].toLocaleString('fr'),
                        sector3_nb:    rpr['max'][4][0].toLocaleString('fr'),
                        sector4_nb:    rpr['max'][4][1].toLocaleString('fr')
                      })}
                    </div>
                  </div>
                  {parseInt(this.props.data.region) < 100 ? <div className="textExplanationContainer">
                    <div className="textExplanation">
                      {
                        t('region_population_rhythm_text3', {
                          nbOfResidents: rpr['max'][2].toLocaleString('fr'),
                          unit:          this.props.usePercent ? t('unit.pct.persons') : t('unit.persons_text'),
                          sector1:       t(rpr['max'][3][0]).toLocaleString('fr'),
                          sector2:       t(rpr['max'][3][1]).toLocaleString('fr'),
                          sector3:       t(rpr['max'][3][2]).toLocaleString('fr'),
                          sector1_nb:    rpr['max'][4][0].toLocaleString('fr'),
                          sector2_nb:    rpr['max'][4][1].toLocaleString('fr'),
                          sector3_nb:    rpr['max'][4][2].toLocaleString('fr'),
                        })
                      }
                    </div>
                  </div>
                  : null}
                </div> :
                <StackedDataComponent
                  data={parseInt(this.props.data.region) < 100 ? data.region_population_rhythm : data.simplified_region_population_rhythm}
                  useTables={this.props.useTables}
                  title={parseInt(this.props.data.region) < 100 ? "region_population_rhythm" : "simplified_region_population_rhythm"}
                  grouping="persons"
                  usePercent={this.props.usePercent}
                  sorting="region"
                  showOutOfTerritory={false}
                  ra={this.props.sm.properties.ra}
                />
              }
            </Collapsible>
            {this.props.sm.properties.codesm <= 8 ? <Collapsible triggerClassName="odd" triggerOpenedClassName="odd" trigger={t('municipalities_list', {scope: t([5,6,7,8].includes(this.props.sm.id) ? 'municipalities' : [2,3,4].includes(this.props.sm.id) ? "municipalities_and_boroughs" : "boroughs")})} lazyRender={true}>
              <div className="chartSuperContainer">
                {has_boroughs ? <div><div className="municipalitiesSubtitle">{t('Boroughs')}</div><div className="municipalitiesContainer">{municipalitiesByRegion[this.props.sm.id].filter(m => m.includes('(A)')).map(m => <div key={`${this.props.sm.id}_${m}`} className="municipalityName">{m.replace('(P)', ` (${t('part')})`).replace('(A)','')}</div>)}</div></div> : null}
                {has_municipalities ? <div><div className="municipalitiesSubtitle">{t('Municipalities')}</div><div className="municipalitiesContainer">{municipalitiesByRegion[this.props.sm.id].filter(m => !m.includes('(A)')).map(m => <div key={`${this.props.sm.id}_${m}`} className="municipalityName">{m.replace('(P)', ` (${t('part')})`).replace('(A)','')}</div>)}</div></div> : null}
              </div>
            </Collapsible> : null}
          </div>
        </div>
      </div>
    )
  }
}

/*
          <div className="smContent">
            <SingleLevelDrillableComponent />
          </div>
*/
export default withTranslation()(Panel);