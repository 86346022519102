import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import Modal from './GenericModal.js';
import './modal.css';

class GlossaryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didMount: false
    }
  }

  componentDidMount() {
    this.setState({didMount: true})
  }

  render() {
    const {t} = this.props;
    return(
      <Modal onBorderClick={this.props.onBorderClick}>
        <div className="genericModalContent">
          <div className="genericModalTitle">{t('glossary')}</div>
          <div className="glossaryModalTerm">{t('glossary.t.mode')}</div>
          <div className="glossaryModalTable"><table><thead></thead><tbody>{t("graph_desc.trip_mode").split('\n').map(s => <tr key={s}>{s.split(':').map(e => <td key={e}>{e}</td>)}</tr>)}</tbody></table></div>
          <div className="glossaryModalTerm">{t('glossary.t.modal_share')}</div>
          <div className="glossaryModalDefinition">{t('glossary.d.modal_share')}</div>
          <div className="glossaryModalTerm">{t('glossary.t.am')}</div>
          <div className="glossaryModalDefinition">{t('glossary.d.am')}</div>
          <div className="glossaryModalTerm">{t('glossary.t.household')}</div>
          <div className="glossaryModalDefinition">{t('glossary.d.household')}</div>
          <div className="glossaryModalTerm">{t('glossary.t.sm')}</div>
          <div className="glossaryModalDefinition">{t('glossary.d.sm')}</div>
          <div className="glossaryModalTerm">{t('glossary.t.ra')}</div>
          <div className="glossaryModalDefinition">{t('glossary.d.ra')}</div>
          <div className="glossaryModalTerm">{t('glossary.t.expansion')}</div>
          <div className="glossaryModalDefinition">{t('glossary.d.expansion')}</div>
        </div>
      </Modal>
    )
  }

}

export default withTranslation()(GlossaryModal);